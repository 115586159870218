import React from 'react';
import styled from 'styled-components';
// assets
import HeroIllustration from '../../../images/product-assets/security_and_compliance_hero_illustration.svg';

const Container = styled.section`
    padding-top: 50px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(
        70.24deg,
        #44a1f3 -0.48%,
        #44a1f3 51.16%,
        #74cff0 82.14%,
        #97f1ee 102.79%
    );
    border-radius: 0 0 32px 32px;

    @media only screen and (max-width: 850px) {
        background: linear-gradient(
            to bottom right,
            #44a1f3 -0.48%,
            #44a1f3 51.16%,
            #74cff0 82.14%,
            #97f1ee 102.79%
        );
    }
`;
const HeroContainer = styled.div`
    height: 650px;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        padding: 0 46px;
    }

    @media screen and (max-width: 850px) {
        height: 744px;
        flex-direction: column;
        justify-content: center;
        gap: 56px;
    }

    @media screen and (max-width: 500px) {
        height: 546px;
    }
`;
const LeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 536px;
    gap: 24px;

    @media only screen and (max-width: 1024px) {
        max-width: 438.2px;
    }

    @media only screen and (max-width: 850px) {
        max-width: 538px;
    }
`;

const RightDiv = styled.div``;

const Heading = styled.h1`
    font-weight: 600;
    font-size: 64px;
    line-height: 75.84px;
    color: rgba(255, 255, 255, 1);

    @media only screen and (max-width: 1024px) {
        font-size: 46px;
        line-height: 48px;
    }
    @media only screen and (max-width: 850px) {
        text-align: center;
        line-height: 52.14px;
        font-size: 44px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 28.44px;
        max-width: 169px;
        margin: 0 auto;
    }
`;
const SubHeading = styled.p`
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    line-height: 21.33px;
    font-weight: 400;

    @media only screen and (max-width: 850px) {
        text-align: center;
        font-size: 16px;
        line-height: 18.96px;
    }
    @media only screen and (max-width: 500px) {
        font-family: Rubik;
        font-size: 14px;
        line-height: 16.59px;
        max-width: 320px;
    }
`;
const Image = styled.img`
    width: 434.83px;
    height: 386.14px;

    @media only screen and (max-width: 1024px) {
        width: 387.94px;
        height: 323.77px;
    }

    @media only screen and (max-width: 850px) {
        width: 309.84px;
        height: 275.14px;
    }

    @media only screen and (max-width: 500px) {
        width: 258.14px;
        height: 229.23px;
    }
`;

const StyledSupperScript = styled.sup`
    font-weight: 500;
    font-size: 40px;

    @media only screen and (max-width: 820px) {
        font-size: 30px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 18px;
    }
`;
const HeroSection = () => {
    return (
        <Container>
            <HeroContainer>
                <LeftDiv>
                    <Heading>
                        Security at Authenticate<StyledSupperScript>®</StyledSupperScript>
                    </Heading>
                    <SubHeading>
                        Learn about the compliance standards Authenticate<sup>®</sup> follows,
                        including HIPAA and SOC 2, to guarantee the highest level of data security
                        and privacy protection for our clients.
                    </SubHeading>
                </LeftDiv>
                <RightDiv>
                    <Image src={HeroIllustration} alt="Hero" draggable="false" />
                </RightDiv>
            </HeroContainer>
        </Container>
    );
};

export default HeroSection;
