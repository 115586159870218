import { Dialog, DialogContent, Slide } from '@mui/material';
import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ContactUsForm from '../../../components/ContactUsForm';

const StyledDialogContent = styled(DialogContent)`
    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #dddddd;
        border-radius: 10px;
        width: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    /* scrollbar on mobile devices */
    @media only screen and (max-width: 560px) {
        ::-webkit-scrollbar {
            width: 4px;
        }
    }
    padding: 0px 0 !important;
    overflow-x: hidden;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ContactUsFormPopup = ({ open, handleClose }) => {
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (isFormSubmitted) {
            handleClose();
            setIsFormSubmitted(false);
        }
    }, [isFormSubmitted, handleClose]);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            fullHeight
            maxWidth="md"
            scroll="paper"
            PaperProps={{
                sx: {
                    borderRadius: '16px',
                },
            }}
        >
            <StyledDialogContent>
                <ContactUsForm
                    handleOnSubmit={() => setIsFormSubmitted(true)}
                    buttonStyle="sticky"
                />
            </StyledDialogContent>
        </Dialog>
    );
};

ContactUsFormPopup.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default ContactUsFormPopup;
