import React from 'react';
import RootLayout from './../../layouts/RootLayout';
import HeroSection from '../../sections/Resources/SecurityAndCompliance/HeroSection.jsx';
import DataAndSecurityHighlight from '../../sections/Resources/SecurityAndCompliance/DataAndSecurityHighlight.jsx';
import ComplianceMeasures from '../../sections/Resources/SecurityAndCompliance/ComplianceMeasures.jsx';
import SecurityMeasures from '../../sections/Resources/SecurityAndCompliance/SecurityMeasures.jsx';
import HighlightProducts from '../../sections/Resources/SecurityAndCompliance/HighlightProducts.jsx';
import ProductSeo from '../../components/ProductSeo';

const SecurityAndCompliance = () => {
    return (
        <RootLayout>
            <ProductSeo
                title="Security and Compliance"
                description="Explore comprehensive security and compliance at Authenticate®. We adhere to SOC 2, HIPAA, PCI DSS, GDPR, CCPA, DPPA, and GLBA standards, ensuring your data is protected and privacy maintained with the highest industry measures."
                ogImage={
                    'https://cdn.authenticating.com/public/website/logos/authenticate-main-open-graph-image.png'
                }
            />
            <HeroSection />
            <ComplianceMeasures />
            <SecurityMeasures />
            <HighlightProducts />
            <DataAndSecurityHighlight />
        </RootLayout>
    );
};

export default SecurityAndCompliance;
